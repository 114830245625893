<template>
  <div id="app">
    <div class="bank_banner2 clear">
      <img src="../../../src/assets/images/account_agreement_banner.png" alt=""/>
      <div class='bank_banner_l iconfont' @click="$router.back(-1)">&#xe620;</div>
    </div>
    <div class="step1" v-show="step1Show">
      <div class="txb2">
        <div class="bank_sub_title">
          <span><font class=""></font>请完善以下资料</span>
        </div>

        <div class="txb2 mgb_0">
          <van-field
              v-model="info.name"
              required
              type="name"
              label="姓名"
              maxlength="11"
              placeholder="请输入姓名"
              :formatter="nameFormatter"
              @input="checkIsOkToNext"
          />
        </div>
        <div class="txb2 mgb_0">
          <van-field
              v-model="info.idCard"
              required
              type="idcard"
              label="身份证号码"
              maxlength="18"
              placeholder="请输入身份证号码"
              :formatter="allFormatter"
              @input="checkIsOkToNext"
          />
        </div>
        <div class="txb2 mgb_0">
          <van-field
              v-model="info.bankCard"
              required
              type="digit"
              label="银行卡号"
              maxlength="19"
              placeholder="请输入银行卡号"
              :formatter="allFormatter"
              @input="checkIsOkToNext"
          />
        </div>
        <div class="txb2 mgb_0">
          <van-field
              v-model="info.mobile"
              required
              type="tel"
              label="预留手机"
              maxlength="11"
              placeholder="请输入预留手机号"
              :formatter="allFormatter"
              @input="checkIsOkToNext"
          />
        </div>
        <div class="txb2 mgb_0">
          <van-field
              v-model="info.code"
              required
              readonly
              type="tel"
              label="验证码"
              maxlength="6"
              placeholder="请输入验证码"
              @click="showKeyboard3=true"
              @input="checkIsOkToNext">
            <template #button>
              <div class="get_code" v-if="!showMsgCodeCountdown" @click="sendMsgCode">获取验证码</div>
              <div class="get_code forbidden" v-if="showMsgCodeCountdown">{{ msgCodeCountdownTimes }}</div>
            </template>
          </van-field>

        </div>
        <div class="txb2 mgb_0">
          <van-field
              v-model="info.password"
              required
              readonly
              maxlength="6"
              type="password"
              label="支付密码"
              placeholder="请输入6位缴费充值支付密码"
              @click="showKeyboard1=true"
          />
        </div>
        <div class="txb2 mgb_0">
          <van-field
              readonly
              maxlength="6"
              v-model="info.repassword"
              required
              type="password"
              label="确认支付密码"
              placeholder="请再次输入6位支付密码"
              @click="showKeyboard2=true"
          />
        </div>

        <div class="height_5vw"></div>
        <div class="bank_btn ">
          <van-button color="#e9331e" :disabled="!isOkToNext" @click="check">确认</van-button>
        </div>

      </div>
    </div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>加载中...</van-loading>
    </div>

    <!-- 数字键盘 -->
    <van-number-keyboard
        v-model="info.password"
        :show="showKeyboard1"
        @blur="showKeyboard1 = false"
        @input="checkPasswordIsValid"
        @hide="checkIsOkToNext"
    />

    <!-- 数字键盘 -->
    <van-number-keyboard
        v-model="info.repassword"
        :show="showKeyboard2"
        @blur="showKeyboard2 = false"
        @input="checkRepasswordIsValid"
        @hide="checkIsOkToNext"
    />

    <!-- 数字键盘 -->
    <van-number-keyboard
        v-model="info.code"
        :show="showKeyboard3"
        @blur="showKeyboard3 = false"
        @hide="checkIsOkToNext"
    />
  </div>
</template>

<style scoped>
#app {
  background: #f8f8f8;
  height: 100vh;
  overflow: scroll;
  position: relative;
  z-index: 2000;
}

</style>

<script>
import Vue from 'vue';
import {post} from "@/utils/http";
import {getUserIpConfig} from '@/utils/ipUtils'
import Notify from "vant/es/notify";
import {Button, Toast} from 'vant';
import {nameFormatter, allFormatter, allFormatterWithoutMinority} from "../../utils/formatter";

Vue.use(Button);

export default {
  data() {
    return {
      isOkToNext: false,
      msgCodeCountdownTimer: null,
      msgCodeCountdownTimes: 60,
      showMsgCodeCountdown: false,

      loading: false,

      step1Show: true,
      step2Show: false,
      step3Show: false,

      showKeyboard1: false,
      showKeyboard2: false,
      showKeyboard3: false,

      info: {
        merchantNum: "540",
        name: "",
        idCard: "",
        bankCard: "",
        mobile: "",
        code: "",
        msgCodeCheckId: "",
        password: "",
        repassword: ""
      },
    };
  },
  methods: {
    checkPasswordIsValid() {
      console.log(this.info.password)
      if (this.info.password.length + 1 === 6 || this.info.password.length === 6) {
        this.showKeyboard1 = false;
      } else {
        this.isOkToNext = false;
        return;
      }

      this.checkIsOkToNext();
    },
    checkRepasswordIsValid() {
      console.log(this.info.repassword)
      if (this.info.repassword.length + 1 === 6 || this.info.repassword.length === 6) {
        this.showKeyboard2 = false;
      } else {
        this.isOkToNext = false;
        return;
      }

      this.checkIsOkToNext();
    },
    checkIsOkToNext() {
      if (!this.info.name || !this.stringTrim(this.info.name)) {
        console.log("!this.info.name");
        this.isOkToNext = false;
        return;
      } else if (!this.info.idCard || !this.stringTrim(this.info.idCard)) {
        console.log("!this.info.idCard");
        this.isOkToNext = false;
        return;
      } else if (!this.info.bankCard || !this.stringTrim(this.info.bankCard)) {
        console.log("!this.info.bankCard");
        this.isOkToNext = false;
        return;
      } else if (!this.info.mobile || !this.stringTrim(this.info.mobile)) {
        console.log("!this.info.mobile");
        this.isOkToNext = false;
        return;
      } else if (!this.info.code || !this.stringTrim(this.info.code)) {
        console.log("!this.info.code");
        this.isOkToNext = false;
        return;
      } else if (!this.info.password || !this.stringTrim(this.info.password)) {
        console.log("!this.info.password");
        this.isOkToNext = false;
        return;
      } else if (!this.info.repassword || !this.stringTrim(this.info.repassword)) {
        console.log("!this.info.repassword");
        this.isOkToNext = false;
        return;
      }

      this.isOkToNext = true;

    },
    check() {
      if (!this.validateName(this.info.name)) {
        Notify({type: "warning", message: '姓名格式错误，请输入英文或汉字'});
        return;
      } else if (!this.validateIdCard(this.info.idCard)) {
        Notify({type: "warning", message: '身份证号码格式错误'});
        return;
      } else if (!this.validateBankCard(this.info.bankCard)) {
        Notify({type: "warning", message: '银行卡号格式错误'});
        return;
      } else if (!this.validateMobile(this.info.mobile)) {
        Notify({type: "warning", message: '预留手机号格式错误'});
        return;
      } else if (!this.validateCode(this.info.code)) {
        Notify({type: "warning", message: '验证码格式错误'});
        return;
      } else if (!this.validateCode(this.info.password)) {
        Notify({type: "warning", message: '密码格式错误'});
        return;
      } else if (!this.validateCode(this.info.repassword)) {
        Notify({type: "warning", message: '确认密码格式错误'});
        return;
      }

      if (this.info.repassword != this.info.password) {
        Notify({type: "warning", message: '两次电子钱包密码不一致'});
        return;
      }

      // 去除前后空格
      let that = this;
      Object.keys(that.info).forEach(function (key) {
        that.info[key] = that.stringTrim(that.info[key])
      });

      this.loading = true;

      // 获取IP
      getUserIpConfig().then(res => {
        if (!res || res === '') {
          this.loading = false;
          Toast("IP获取异常，请联系客服处理，谢谢");
          return;
        }

        // 开通三类户
        post('/api/guangfa/v2/create/account/third', {
          accountOpenType: "3",
          certNum: this.info.idCard,
          certType: '1', // 必选
          mobile: this.info.mobile,
          bindingCardNo: this.info.bankCard,
          name: this.info.name,
          paymentPlatformId: "73d1c2341636435f8e364f9db552a277", // 广发写死
          smsCode: this.info.code,
          orgQryId: this.info.msgCodeCheckId,
          cardType: '1',
          lbsInfo: '+' + res.location.lng + '/+' + res.location.lat,
          ipAddress: res.ip,
          country: 'CN', // 必选
          password: this.info.password,
        }).then(result => {
          this.loading = false;
          this.$router.replace({path: '/AccountCreateFeedback', query: {type: 'SUCCESS'}})
        }).catch(error => {
          this.loading = false;
          this.$router.replace({path: '/AccountCreateFeedback', query: {type: 'FAIL', msg: error.message}})
        })

      });
    },
    sendMsgCode() {
      if (this.info.mobile) {
        if (this.validateMobile(this.info.mobile)) {
          post('/api/guangfa/v2/sendMsgCode', {
            merchantNum: this.info.merchantNum,
            mobileNo: this.info.mobile,
            msgContentPart1: '进行电子钱包的银行卡绑定',
            certNo: this.info.certNum

          }).then(result => {
            Notify({type: "success", message: "短信已发送"});
            //下次发送倒数
            this.showMsgCodeCountdown = true;
            this.msgCodeCountdownTimer = setInterval(() => {
              this.msgCodeCountdownTimes--;
              if (this.msgCodeCountdownTimes === 0) {
                clearInterval(this.msgCodeCountdownTimer);
                this.showMsgCodeCountdown = false;
                this.msgCodeCountdownTimes = 60;

              }
            }, 1000);
            this.info.msgCodeCheckId = result.data.msgCodeCheckId

          }).catch(err => {
            Notify({type: "danger", message: err.message});

          })
        } else {
          Notify({type: "danger", message: '预留手机号码格式错误'});
        }
      } else {
        Notify({type: "danger", message: '预留手机号码不能为空'});
      }

    },
    validateName(name) {
      const nameReg = /^[A-Za-z0-9\u4E00-\u9FA5·s]{2,20}$/;
      if (name && nameReg.test(name)) {
        return true;
      } else {
        return false;
      }
    },
    validateIdCard(idCard) {
      const reg = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
      if (idCard && reg.test(idCard)) {
        return true;
      } else {
        return false;
      }
    },
    validateBankCard(bankCard) {
      if (bankCard && bankCard.toString().length >= 16) {
        return true;
      } else {
        return false;
      }
    },
    validateMobile(mobile) {
      const mobileReg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
      if (mobile && mobileReg.test(mobile)) {
        return true;
      } else {
        return false;
      }
    },
    validateCode(code) {
      const reg = /^[0-9]{6}$/
      if (code && reg.test(code)) {
        return true;
      } else {
        return false;
      }
    },
    stringTrim(str) {
      if (str.trim) return str.trim()
      return str;
    },
    nameFormatter(value) {
      return nameFormatter(value);
    },
    allFormatterWithoutMinority(value) {
      return allFormatterWithoutMinority(value);
    },
    allFormatter(value) {
      return allFormatter(value);
    },
  }
};
</script>
