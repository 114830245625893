import {jsonp} from 'vue-jsonp'
import config from '../config/config.json'

export function getUserIpConfig() {
    return new Promise((resolve, reject) => {
        jsonp(`https://apis.map.qq.com/ws/location/v1/ip?key=${config.MapKey}`, {
            output: 'jsonp'
        }).then(resp => {
            if (resp && resp.status === 0 && resp.result && resp.result.ip && resp.result.ip !== '') {
                resolve({ip: resp.result.ip, location: resp.result.location})
            }
            resolve(null);
        }).catch(err => {
            console.log(err);
            reject(err);
        })
    })
}